<template>
    <div class="page-content header-clear-small">
        
        <div class="card card-style preload-img" data-src="assets/images/ypodo_intro2.jpg" data-card-height="130" style="height:130px">
            <div class="card-center ml-3">
                <h1 class="color-white mb-0">À propos d'Ypodo</h1>
                <p class="color-white mt-n1 mb-0">Ouvrez-vous la ville.</p>
            </div>
            
            <div class="card-overlay bg-black opacity-30"></div>
        </div>
		<div class="card card-style contact-form">
            <div class="content">
			<p class="text-uppercase pt-2 pb-0 mb-1 font-900 ">À propos d'Ypodo</p>
			<p>Ypodo, l’application de signalement et de communication de votre ville.</p>
			</div>
		</div>
 
    </div>
    <!-- End of Page Content--> 
</template>
<script>

export default {
  name: 'About',
  data() {
    return{
	}
  },
  components: {
    Footer
  },
  props: {	
  },  
  created() {
     
  },  
  mounted() {
  },   
  methods: {
  }
}
</script>

